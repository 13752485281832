<template lang="html">
  <div>
    <div
      v-if="store.state.selectedEventType === 'occasion'"
      class="row mx-0 px-0 col-md-12 py-2 wow bounceInUp"
    >
      <div
        v-if="store.state.mode.name === 'EventDonation'"
        class="row mx-0 px-0 col-md-12 py-2 wow bounceInUp"
      >
        <div
          v-for="item in store.state.occasionsList"
          :key="item.EventCampaignId"
          class="col-md-3 col-12 mb-4"
        >
          <div @click="eventItemClicked(item.PageCreateUrl)">
            <EventItemWithDonation
              :name="item.Name"
              :image-url="item.Image"
              :event-type="item.EventType"
              :event-total="item.Total"
              :url="item.Url"
              :target="item.Target"
              :default-page-image-path="item.DefaultPageImagePath"
              :default-beneficiary-id="item.DefaultBeneficiaryId"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="row mx-0 px-0 col-md-12 py-2 wow bounceInUp"
      >
        <div
          v-for="item in store.state.occasionsList"
          :key="item.EventCampaignId"
          class="col-md-6 col-12 mb-4"
        >
          <div @click="eventItemClicked(item.PageCreateUrl)">
            <EventItem
              :name="item.Name"
              :image-url="item.Image"
              :event-type="item.EventType"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="10"
      infinite-scroll-throttle-delay="200"
      infinite-scroll-immediate-check="false"
    >
      <div
        v-if="store.state.selectedEventType !== 'occasion'"
        class="row mx-0 px-0 col-md-12 py-2"
      >
        <div
          v-if="store.state.mode.name === 'EventDonation'"
          class="row mx-0 px-0 col-md-12 py-2 wow bounceInUp"
        >
          <div
            v-for="item in store.state.eventsList"
            :key="item.EventCampaignId"
            class="col-md-3 col-12 mb-4 px-1 wow bounceInUp"
          >
            <div @click="eventItemClicked(item.Url)">
              <EventItemWithDonation
                :name="item.Name"
                :image-url="item.Image"
                :event-type="item.EventType"
                :event-total="item.Total"
                :url="item.Url"
                :target="item.Target"
                :default-page-image-path="item.DefaultPageImagePath"
                :default-beneficiary-id="item.DefaultBeneficiaryId"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="row mx-0 px-0 col-md-12 py-2 wow bounceInUp"
        >
          <div
            v-for="item in store.state.eventsList"
            :key="item.EventCampaignId"
            class="col-md-6 col-12 mb-4 wow bounceInUp"
          >
            <div @click="eventItemClicked(item.Url)">
              <EventItem
                :name="item.Name"
                :image-url="item.Image"
                :event-type="item.EventType"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../common/store";
import EventItem from "./EventItem";
import Logger from "../common/logger";
import EventItemWithDonation from "./EventItemWithDonation";
import logger from "../common/logger";

export default {
  name: "EventsGrid",
  components: {
    EventItem,
    EventItemWithDonation
  },

  props: {},
  data() {
    return {
      store: Store,
      busy: false
    };
  },

  mounted() {
    logger.logInfo("mounted events grid");
    logger.logInfo(this.store.state.mode.name);
  },

  methods: {
    eventItemClicked(url) {
      Logger.logInfo(`Navigation to eventUrl: ${url}`);
      window.location = url;
    },

    async loadMore() {
      this.store.incrementPageIndex();
      var events = await this.$eventsService.getEventsByQuery(
        this.store.state.searchText,
        this.store.state.pageIndex
      );

      if (events.length > 0) this.store.addEvents(events);
    }
  }
};
</script>

<style lang="css"></style>
