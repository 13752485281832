<template lang="html">
  <div>
    <div
      :title="name"
      class="card bg-light-grey"
      :style="cardBorderColor"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div class="py-0">
        <h5 class="align-middle text-center py-2 my-0 font-weight-500 font-color-black">
          {{ name }}
        </h5>
      </div>
      <div class="light">
        <div class="table">
          <div class="align-top">
            <img
              class="card-img-top px-2"
              :class="{
                'obj-cover': eventType === 'Generic'
              }"
              :src="
                $gfImagePlugin.getImageWithResolution(
                  imageUrl,
                  1000
                )
              "
              alt="Card image cap"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../common/store";
import Logger from "../common/logger";

export default {
    name: "EventItem",
    props: ["name", "imageUrl", "eventType"],
    data() {
        return {
            store: Store,
            hover: false,
            hoverColor: "#f47d32",
            cardBorderColor: {
                borderColor: "#dedede"
            }
        };
    },

    watch: {
        hover: function(val) {
            if (val === true) {
                this.cardBorderColor.borderColor = this.hoverColor;
            } else {
                this.cardBorderColor.borderColor = "#dedede";
            }
        }
    },
    mounted() {
        if (
            this.store.state.eventTheme &&
            this.store.state.eventTheme.DriverButtonBackgroundColour &&
            this.store.state.eventTheme.DriverButtonBackgroundColour.length > 0
        ) {
            this.hoverColor = this.store.state.eventTheme.DriverButtonBackgroundColour;

            Logger.logInfo(
                `Using hover color  ${this.store.state.eventTheme.DriverButtonBackgroundColour}`
            );
        }
    }
};
</script>

<style scoped>
.font-weight-500 {
  font-weight: 500;
}

.card-img-top {
    height: 120px;
    object-fit: contain;
}

.bg-light-grey {
    background-color: #e8e8e86b !important;
}

.obj-cover {
    object-fit: cover !important;
}

.card {
    overflow: hidden;
    border: 2px solid #dedede;
}
@media (hover: hover) {
    .card:hover {
        cursor: pointer;
        border: 2px solid;
        -webkit-transition: all 0.5s;
    }

    .card:hover {
        display: block;
        -webkit-transition: all 0.5s;
    }
}

.card .card-body {
    background-color: #e8e8e86b;
    font-size: 13px;
}
.card-text {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.font-color-black {
  color: rgb(0,0,0) !important;
}
</style>
