<template lang="html">
  <div>
    <div
      :title="name"
      class="card bg-light-grey"
      :style="cardBorderColor"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div class="light">
        <div class="table">
          <div class="align-top">
            <div
              class="event-banner"
              :style="{backgroundImage: 'url(\'' +$gfImagePlugin.getImageWithResolution(
                imageUrl,
                1000
              ) + '\')'}"
            />
            <div
              class="item-image"
              :style="{backgroundImage: `url(${defaultPageImagePath})`}"
            />
            <div class="py-0">
              <h5 class="align-middle text-center py-2 my-0 font-weight-500 font-color-black truncate">
                {{ name }}
              </h5>
            </div>
            
            <div class="row">
              <div
                class="col"
                :class="{'col-12': !isEventHasTarget, 'col-6': isEventHasTarget}"
              >
                <div class="col-12 text-center">
                  Raised  
                </div>
                <div class="col-12 px-0 text-center">
                  <b> ${{ parsedRaised }} </b>
                </div>
              </div>
              <div
                v-if="isEventHasTarget"
                class="col-6 border-left border-3"                
              >
                <div class="col-12 text-center">
                  Goal 
                </div>
                <div class="col-12 px-0 text-center">
                  <b> ${{ parsedTarget }} </b>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex justify-content-center">
                <a
                  class="btn button-yellow"
                  :href="donationUrl"
                >  {{ store.state.mode.settings.donationText }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../common/store";
import Logger from "../common/logger";
import logger from "../common/logger";

export default {
  name: "EventItem",
  props: [
    "name",
    "imageUrl",
    "eventType",
    "eventTotal",
    "url",
    "target",
    "defaultPageImagePath",
    "defaultBeneficiaryId"
  ],
  data() {
    return {
      store: Store,
      hover: false,
      hoverColor: "#f47d32",
      cardBorderColor: {
        borderColor: "#dedede"
      }
    };
  },
  computed: {
    donationUrl() {
      return `${this.url}/payments/donate/beneficiary/${this.defaultBeneficiaryId}`;
    },
    isEventHasTarget() {
      return typeof this.target !== "undefined" && this.target !== null > 0;
    },
    parsedRaised() {
      return parseFloat(this.eventTotal)
        .toFixed(2)
        .toString();
    },
    parsedTarget() {
      return parseFloat(this.target)
        .toFixed(2)
        .toString();
    }
  },

  watch: {
    hover: function(val) {
      if (val === true) {
        this.cardBorderColor.borderColor = this.hoverColor;
      } else {
        this.cardBorderColor.borderColor = "#dedede";
      }
    }
  },
  mounted() {
    Logger.logInfo(
      `event target ${typeof this.target !== "undefined" ||
        this.target !== null > 0}`
    );
    if (
      this.store.state.eventTheme &&
      this.store.state.eventTheme.DriverButtonBackgroundColour &&
      this.store.state.eventTheme.DriverButtonBackgroundColour.length > 0
    ) {
      this.hoverColor = this.store.state.eventTheme.DriverButtonBackgroundColour;

      Logger.logInfo(
        `Using hover color  ${this.store.state.eventTheme.DriverButtonBackgroundColour}`
      );
    }
  }
};
</script>


<style scoped>
.font-weight-500 {
  font-weight: 500;
}

.card-img-top {
  height: 120px;
  object-fit: contain;
}

.bg-light-grey {
  background-color: #e8e8e86b !important;
}

.obj-cover {
  object-fit: cover !important;
}

.card {
  overflow: hidden;
  border: 2px solid #dedede;
}
@media (hover: hover) {
  .card:hover {
    cursor: pointer;
    border: 2px solid;
    -webkit-transition: all 0.5s;
  }

  .card:hover {
    display: block;
    -webkit-transition: all 0.5s;
  }
}

.card .card-body {
  background-color: #e8e8e86b;
  font-size: 13px;
}
.card-text {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.font-color-black {
  color: rgb(0, 0, 0) !important;
}
.button-yellow {
  background: #edbc22 !important;
  color: #fff !important;
}

.btn[data-v-3dc72565] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 18px;
  padding: 2px 50px;
  margin: 10px 0;
  background: #ff6600 !important;
  color: #fff !important;
  border-radius: 18px !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* test over image */
.event-banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #f47d32;
}

.event-banner {
  background-color: #e1e1e1;
  height: 80px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  position: relative;
  opacity: 0.7;
}
.item-image {
  background-repeat: no-repeat;
  height: 120px;
  width: 120px;
  background-size: cover;
  background-position: center center;
  margin: -60px auto 15px;
  border-radius: 500px;
  background-color: #ddd;
  display: block;
  overflow: hidden;
  position: relative;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px;
}
.border-3 {
  border-width: 3px !important;
}
</style>
