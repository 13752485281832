<script>
import Vue from "vue";
import axios from "axios";
import { text } from "@fortawesome/fontawesome-svg-core";

export default {
    install(Vue, options) {
        var store = options.store;
        
        Vue.prototype.$eventsService = {
            async getInternalEvents() {
                return this.mapToEvent(
                    await this.getEvents("I", true, null, 20),
                    "Generic"
                );
            },

            async getStandardEvents(pageIndex) {
                return this.mapToEvent(
                    await this.getEvents(null, true, pageIndex),
                    "Standard"
                );
            },

            async getEventsByQuery(textQuery, pageIndex) {
                var includeSubEvents = store.state.eventId != null;
                return this.mapToEvent(
                    await this.getEvents(
                        null,
                        includeSubEvents,
                        pageIndex,
                        null,
                        textQuery
                    )
                );
            },

            async getTheme() {
                var eventItems = await this.getEvents(null, false, null, 1);
                if (eventItems.length > 0) return eventItems[0].EventTheme;
                return null;
            },

            mapToEvent(eventResponseItems, eventType) {
                return eventResponseItems.map(x => {
                    return {
                        Id: x.EventCampaignId,
                        EventType: eventType,
                        PageCreateUrl: x.EventCreatePageUrl,
                        Name: x.EventName,
                        Image: x.EventImagePath,
                        Url: x.EventUrl,
                        Location: x.Location,
                        BannerImage: x.EventTheme.BannerImage,
                        Total: x.EventTotal,
                        DefaultBeneficiaryId: x.DefaultBeneficiaryId,
                        DefaultPageImagePath: x.DefaultPageImagePath,
                        Target: x.EventTarget
                    };
                });
            },

            async getEvents(
                eventType,
                includeSubEvents,
                pageIndex,
                pageSize,
                textQuery
            ) {
                var results = [];
                let params_query = {
                    params: {
                        region: store.state.regionCode
                    }
                };
                var currDate = new Date();
                currDate.setMonth(currDate.getMonth() - store.state.restrictEventsFromMonths);
                var searchFrom = `${currDate.getFullYear()}-${currDate.getMonth() + 1}-${currDate.getDate()}`;

                if (!pageIndex) pageIndex = 1;

                if (!pageSize) pageSize = 12;

                params_query.params["pageindex"] = pageIndex;
                params_query.params["pagesize"] = pageSize;

                if (textQuery && textQuery.length > 0)
                    params_query.params["query"] = textQuery;

                if (eventType && eventType.length > 0)
                    params_query.params["eventtype"] = eventType;

                if (store.state.eventId && store.state.eventId.length > 0) {
                    params_query.params["eventcampaignid"] = store.state.eventId;
                    params_query.params["includesubevents"] = includeSubEvents;
                    params_query.params["includesubevents"] = includeSubEvents;
                    if (store.state.useDateRestrictionOnSubEventSearch)
                        params_query.params["datefrom"] = searchFrom;
                } else {
                    if (store.state.useDateRestrictionForEventSearch && eventType !== 'I')
                        params_query.params["datefrom"] = searchFrom;
                }

                var res = await axios.get(
                    `${store.state.apiDomain}/v1/events/search`,
                    params_query
                );

                if (res.data.Events && res.data.Events.length > 0) {
                    results = res.data.Events;
                }

                return results;
            }
        };
    }
};
</script>
