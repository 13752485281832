<template lang="html">
  <ul class="nav nav-pills col-12 mb-2 p-0">
    <li
      class="nav-item btn-square col-6 m-0 p-0 "
      @click="occasionsTabSelected"
    >
      <a
        class="nav-link text-center"
        :class="{
          active: store.state.selectedEventType === 'occasion'
        }"
      >Occasions</a>
    </li>
    <li
      class="nav-item btn-square col-6 m-0 p-0"
      @click="eventsTabSelected"
    >
      <a
        class="nav-link text-center"
        :class="{
          active: store.state.selectedEventType === 'event'
        }"
      >Events</a>
    </li>
  </ul>
</template>
<script>
import Store from "../common/store";

export default {
    name: "EventTypeTabs",
    props: {},
    data() {
        return {
            store: Store
        };
    },

    mounted: function() {},

    methods: {
        occasionsTabSelected() {
            this.store.changeEventType("occasion");
        },
        eventsTabSelected() {
            this.store.changeEventType("event");
        }
    }
};
</script>

<style scoped>
a.nav-link {
    color: #000;
    font-family: "bariolbold";
    font-size: 18px;
}

.nav-pills .nav-link {
    border-radius: 0;
}

.nav-pills .nav-link.active {
    background-color: #fff;
    color: #f57c33;
    border-bottom: 2px solid;
    border-color: #f57c33;
}

.nav-item,
.nav-item:hover {
    color: #f57c33;
    cursor: pointer;
}
</style>
