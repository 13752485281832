<template lang="html">
  <div id="vue-gf-events-search">
    <MainContainer />
  </div>
</template>

<script>
import Vue from "vue";
import Store from "./common/store";
import EventServicePlugin from "./common/EventServicePlugin";
import GfImagePlugin from "./common/GfImagePlugin";
import MainContainer from "./components/MainContainer";
import logger from "./common/logger";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import WowJs from "wowjs";
import infiniteScroll from "vue-infinite-scroll";
import vueDebounce from "vue-debounce";

library.add(faSearch);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(infiniteScroll);
Vue.use(vueDebounce);

export default {
    name: "GfEventsSearch",

    components: { MainContainer },

    props: ["apiDomain", "eventId", "region", "mode"],

    data() {
        return {
            store: Store
        };
    },

    created() {
        if (localStorage && localStorage["GfDebug"] === "true")
            this.store.setToDebugMode();

        new WowJs.WOW().init();
        this.setupServicePlugins();

        if (this.eventId && this.eventId.length > 0)
            this.store.setEventId(this.eventId);

        if (this.apiDomain && this.apiDomain.length > 0)
            this.store.setApiDomain(this.apiDomain);

        if (this.region && this.region.length > 0)
            this.store.setRegionCode(this.region);

        if(this.mode)
            this.store.setMode(this.mode)

        logger.logInfo(this.$eventsService);
    },

    mounted() {},

    methods: {
        setupServicePlugins() {
            logger.logInfo("Loading service plugins");
            var servicePluginOptions = {
                store: this.store
            };

            Vue.use(EventServicePlugin, servicePluginOptions);
            Vue.use(GfImagePlugin, {});
        }
    }
};
</script>

<style lang="css">
@import "assets/css/animate.min.css";

* {
    font-size: 14px;
    font-family: "bariolregular", "Roboto", sans-serif;
}

@font-face {
    font-family: "bariolbold";
    src: url("assets/fonts/Bariol_Bold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "bariolregular";
    src: url("assets/fonts/Bariol_Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
</style>
