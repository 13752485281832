<template lang="html">
  <div>
    <SearchBar />
    <div class="mt-2">
      <EventTypeTabs
        v-if="store.state.searchText.length === 0 && !usingEvent()"
      />
    </div>
    <EventsGrid />
  </div>
</template>

<script>
import Store from "../common/store";
import EventTypeTabs from "./EventTypeTabs";
import EventsGrid from "./EventsGrid";
import SearchBar from "./SearchBar";
import Logger from "../common/logger";

export default {
    name: "MainContainer",

    components: {
        EventTypeTabs,
        EventsGrid,
        SearchBar
    },
    props: {},

    data() {
        return {
            store: Store
        };
    },

    async mounted() {
        this.store.clearEvents();
        this.store.clearOccasions();

        if (
            this.store.state.eventId == null ||
            this.store.state.eventId.length === 0
        ) {
            this.store.addOccassions(
                await this.$eventsService.getInternalEvents()
            );

            this.store.addEvents(await this.$eventsService.getStandardEvents());
        } else {
            this.store.setEventTheme(await this.$eventsService.getTheme());

            this.store.addEvents(await this.$eventsService.getEventsByQuery());
        }
    },

    methods: {
        usingEvent() {
            return (
                this.store.state.eventId && this.store.state.eventId.length > 0
            );
        }
    }
};
</script>

<style lang="css"></style>
