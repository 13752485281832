<template lang="html">
  <div class="row">
    <div class="col-md-8 m-auto">
      <div class="input-group">
        <input
          v-model="searchText"
          v-debounce="searchTextChanged"
          type="text"
          class="form-control et-gf-search rounded-0"
          placeholder="Search"
        >
        <span class="input-group-btn">
          <a
            class="btn btn-search rounded-0"
            type=""
          >
            <font-awesome-icon icon="search" />
          </a>
        </span>
      </div>
      <span
        v-if="loadingSearch"
        class="image_loading_search"
      >
        <img
          src="https://www.gofundraise.com.au/Content/images/loader-orange.gif"
          alt=""
        >
      </span>
      <div
        v-if="valueEnterSearch"
        class="search_result"
      >
        <ul class="list-unstyled">
          <li
            v-for="(item, index) in eventsList"
            :key="index"
            @click="select(item.EventName)"
          >
            {{ item.EventName }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../common/store";
import Logger from "../common/logger";
export default {
    name: "SearchBar",
    props: {},
    data() {
        return {
            store: Store,
            searchText: "",
            eventsReturned: [],
            valueEnterSearch: false,
            loadingSearch: false
        };
    },
    computed: {
        eventsList: {
            get: function() {
                return this.eventsReturned;
            },
            set: function(value) {
                var vm = this;
                if (value != null) {
                    this.eventsReturned = this.eventsReturned.concat(value);
                }
            }
        }
    },
    watch: {
        searchText: function(val) {
            this.store.updateSearchText(val);
        }
    },

    mounted: function() {},

    methods: {
        async searchTextChanged() {
            this.store.clearEvents();
            this.store.addEvents(
                await this.$eventsService.getEventsByQuery(this.searchText)
            );
        },

        select() {}
    }
};
</script>

<style lang="css">
.btn-search {
    background-color: #fff;
    border: 1px solid #ccc;
    height: 100%;
}
</style>
button
