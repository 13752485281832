import logger from "./logger";

export default {
    state: {
        isDebug: false,
        apiDomain: null,
        eventId: null,
        restrictEventsFromMonths: 6,
        useDateRestrictionOnSubEventSearch: false,
        useDateRestrictionForEventSearch: true,
        region: "AU",
        selectedEventType: "occasion",
        eventTheme: null,
        selectedEvent: null,
        pageIndex: 1,
        searchText: "",
        eventsList: [],
        occasionsList: [],
        mode:{name: 'default', settings:{}}
    },

    setMode(mode) {
        this.state.mode = mode;
        logger.logInfo(`Setting mode to`);
        logger.logInfo(mode);
    },

    setEventId(eventId) {
        this.state.eventId = eventId;

        if (eventId) this.changeEventType("event");
        logger.logInfo(`Setting event id to: ${eventId}`);
    },

    setEventTheme(eventTheme) {
        this.state.eventTheme = eventTheme;
        logger.logInfo(`Setting eventTheme to`);
        logger.logInfo(eventTheme);
    },

    setApiDomain(apiDomain) {
        this.state.apiDomain = apiDomain;
        logger.logInfo(`Setting apiDomain to: ${apiDomain}`);
    },

    setRegionCode(regionCode) {
        this.state.regionCode = regionCode;
        logger.logInfo(`Setting regionCode to: ${regionCode}`);
    },

    updateSearchText(text) {
        this.state.pageIndex = 1;
        if (text && text.length > 0) this.changeEventType("event");
        else {
            if (this.state.eventId !== null) this.changeEventType("event");
            else this.changeEventType("occasion");
        }

        this.state.searchText = text;
        logger.logInfo(`Setting searched Text to: ${text}`);
    },

    incrementPageIndex() {
        this.state.pageIndex += 1;
    },

    changeEventType(type) {
        this.state.selectedEventType = type;
        logger.logInfo(`Setting event type selection to: ${type}`);
    },

    addOccassions(items) {
        this.state.occasionsList.push(...items);
        logger.logInfo("Added the following occasion");
        logger.logInfo(items);
    },

    addEvents(items) {
        if (this.state.eventId) 
            items = items.filter((e)=>e.Id != this.state.eventId);
            
        this.state.eventsList.push(...items);        
        logger.logInfo("Added the following events");
        logger.logInfo(items);
    },

    clearEvents() {
        this.state.eventsList = [];
        logger.logInfo("Cleared Events");
    },

    clearOccasions() {
        this.state.occasionsList = [];
        logger.logInfo("Cleared Occasions");
    },

    setToDebugMode() {
        this.state.isDebug = true;
        logger.debug = true;
    }
};
