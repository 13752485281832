<script>
    import Vue from 'vue'
    
    export default {
        install(Vue, options) {
            Vue.prototype.$gfImagePlugin = {
                getImageWithResolution (imagePath, resolution) {
                    if (!imagePath || !resolution)
                        return;
                    return imagePath.replace('images/200/', 'images/' + resolution + '/');
                }
            }
        }
    }
</script>